import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';

import {
  ApiFetchParamsI,
  CommentI,
  PostCommentI,
  ReportReasonI,
} from '@/types';
import api from '.';

const QUERY_LIMIT = 10;

export function createComments() {
  const db = getFirestore();
  const docPath = 'comments';
  const publicationsPath = 'publications';
  const reportsPath = 'publication-reports';
  let lastVisiblePubRef: QueryDocumentSnapshot<DocumentData> | null = null;

  return {
    create: async (params: Omit<CommentI, 'date'>) => {
      const commentParams: CommentI = {
        ...params,
        date: Timestamp.fromDate(new Date()),
      };

      const commentCollection = collection(db, docPath);
      const publicationsCollection = collection(db, publicationsPath);
      const publicationRef = doc(publicationsCollection, params.publicationId);
      const commentRef = doc(commentCollection);
      commentParams.id = commentRef.id;

      if (commentParams.replyTargetRootCommentId) {
        const replyTargetRootCommentRef = doc(
          db,
          docPath,
          commentParams.replyTargetRootCommentId
        );
        await updateDoc(replyTargetRootCommentRef, {
          repliedCommentIds: arrayUnion(commentParams.id),
        });
      }

      await setDoc(commentRef, commentParams);
      await updateDoc(publicationRef, {
        commentsCount: increment(1),
      });

      return commentParams;
    },
    async update(id: string, params: Partial<CommentI>) {
      const commentRef = doc(db, docPath, id);
      return await updateDoc(commentRef, {
        ...params,
        isEdited: true,
      });
    },
    async getAllByPublicationId(id: string, p?: ApiFetchParamsI) {
      const comments: CommentI[] = [];
      const commentsRef = collection(db, docPath);

      console.log('loading all with limit');

      let params;
      if (!p?.fromStart && lastVisiblePubRef) {
        params = query(
          commentsRef,
          where('publicationId', '==', id),
          where('isReply', '==', false),
          where('isSpecialist', '==', false),
          orderBy('date', 'asc'),
          startAfter(lastVisiblePubRef),
          limit(QUERY_LIMIT)
        );
      } else {
        params = query(
          commentsRef,
          where('publicationId', '==', id),
          where('isReply', '==', false),
          where('isSpecialist', '==', false),
          orderBy('date', 'asc'),
          limit(QUERY_LIMIT)
        );
      }

      const querySnapshot = await getDocs(params);
      querySnapshot.forEach(async (doc) => {
        const comment = doc.data() as CommentI;
        comments.push(comment);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < QUERY_LIMIT;
      lastVisiblePubRef = isLastPage ? null : lastVisible;

      return {
        data: comments,
        isLastPage,
      };
    },
    async getAllSepcialistByPublicationId(id: string) {
      const comments: CommentI[] = [];
      const commentsRef = collection(db, docPath);

      const params = query(
        commentsRef,
        where('publicationId', '==', id),
        where('isReply', '==', false),
        where('isSpecialist', '==', true),
        orderBy('date', 'asc'),
        startAfter(lastVisiblePubRef),
        limit(QUERY_LIMIT)
      );

      const querySnapshot = await getDocs(params);
      querySnapshot.forEach(async (doc) => {
        const comment = doc.data() as CommentI;
        comments.push(comment);
      });

      return comments;
    },
    async getPostCommentsByIds(ids: string[], lastCommentId?: string) {
      const comments: CommentI[] = [];
      const commentsRef = collection(db, docPath);
      const postComments: PostCommentI[] = [];

      let params;
      if (lastCommentId) {
        params = query(
          commentsRef,
          where(
            'id',
            'in',
            [...ids].slice(ids.findIndex((id) => id === lastCommentId) + 1)
          ),
          orderBy('date', 'asc'),
          limit(5)
        );
      } else {
        params = query(
          commentsRef,
          where('id', 'in', ids),
          orderBy('date', 'asc'),
          limit(5)
        );
      }

      const querySnapshot = await getDocs(params);
      querySnapshot.forEach(async (doc) => {
        const comment = doc.data() as CommentI;
        comments.push(comment);
      });

      console.log('querySnapshot.size', querySnapshot.size);

      const isLastPage = querySnapshot.size < 5;

      await Promise.all(
        comments.map(async (c) => {
          const user = await api.users.getById(c.authorId);
          if (user) {
            postComments.push({
              ...c,
              user,
              nestedComments: null,
            });
          }
        })
      );

      return {
        data: postComments,
        isLastPage,
      };
    },
    async getAllByUserId(userId: string, p?: ApiFetchParamsI) {
      const comments: CommentI[] = [];
      const commentsRef = collection(db, docPath);

      let params;
      if (!p?.fromStart && lastVisiblePubRef) {
        params = query(
          commentsRef,
          where('authorId', '==', userId),
          orderBy('date', p?.orderDesc ? 'desc' : 'asc'),
          startAfter(lastVisiblePubRef),
          limit(QUERY_LIMIT)
        );
      } else {
        params = query(
          commentsRef,
          where('authorId', '==', userId),
          orderBy('date', p?.orderDesc ? 'desc' : 'asc'),
          limit(QUERY_LIMIT)
        );
      }

      const querySnapshot = await getDocs(params);
      querySnapshot.forEach(async (doc) => {
        const comment = doc.data() as CommentI;
        comments.push(comment);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const isLastPage = querySnapshot.size < QUERY_LIMIT;
      lastVisiblePubRef = isLastPage ? null : lastVisible;

      return {
        data: comments,
        isLastPage,
      };
    },
    async getById(id: string) {
      const res = await getDoc(doc(db, docPath, id));
      return res.data() as CommentI;
    },
    async reactById(id: string, userId: string) {
      const publicationRef = doc(db, docPath, id);

      return updateDoc(publicationRef, {
        reactedUserIds: arrayUnion(userId),
        reactionsCount: increment(1),
      });
    },
    async unreactById(id: string, userId: string) {
      const publicationRef = doc(db, docPath, id);

      return updateDoc(publicationRef, {
        reactedUserIds: arrayRemove(userId),
        reactionsCount: increment(-1),
      });
    },
    async setHiddenById(id: string, isHidden: boolean) {
      const commentRef = doc(db, docPath, id);
      return await updateDoc(commentRef, {
        isHidden,
      });
    },
    async setDeletedById(id: string) {
      const commentRef = doc(db, docPath, id);
      return await updateDoc(commentRef, {
        isDeleted: true,
      });
    },
    async reportById(
      commentId: string,
      publicationId: string,
      userId: string,
      reportReason: ReportReasonI
    ) {
      const reportCollection = collection(db, reportsPath);
      const reportRef = doc(reportCollection);

      await setDoc(reportRef, {
        message: reportReason.message,
        type: reportReason.type,
        reasonId: reportReason.id,
        publicationId,
        commentId,
        userId,
      });

      return reportRef.id;
    },
    async blockById(id: string, userId: string) {
      const commentRef = doc(db, docPath, id);

      const params: Partial<Record<keyof CommentI, any>> = {
        reportingUserIds: arrayUnion(userId),
        reportsCount: increment(1),
      };

      return updateDoc(commentRef, params);
    },
    async unblockById(id: string, userId: string) {
      const commentRef = doc(db, docPath, id);

      const params: Partial<Record<keyof CommentI, any>> = {
        reportingUserIds: arrayRemove(userId),
        reportsCount: increment(-1),
      };

      return updateDoc(commentRef, params);
    },
  };
}
